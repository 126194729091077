import React, { useContext, createContext } from 'react';

export const GlobalConfigContext = createContext();

export const useGlobalConfig = () => {
  return useContext(GlobalConfigContext);
};

export const GlobalConfigProvider = ({ config, children }) => {
  return (
    <GlobalConfigContext.Provider value={{ config }}>
      {children}
    </GlobalConfigContext.Provider>
  );
};
