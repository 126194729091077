import React from 'react';
import { Divider, Tabs, TabsList, TabsTrigger } from 'homebox-ui';
// import { MdCalendarMonth } from 'react-icons/md';
import { useFormikContext } from 'formik';

const BillSummaryCard = ({
  divider = true,
  isMonthly,
  billName,
  group_name,
  price,
  calculateDurationPrice,
  group_id,
  provider,
  billDescription = '',
}) => {
  return (
    <>
      <div className='min-h-16 mt-4 flex justify-between py-2 text-sm text-neutral-600'>
        <div className='flex max-w-[160px] flex-col space-y-2 text-left lg:max-w-[200px]'>
          <p className='text-typography-primary font-medium'>{group_name}</p>
          <p className='text-typography-secondary'>
            {group_id !== 4 && group_id !== 8 && provider}
            {group_id === 5 && ` • ${billName}`}
            {group_id === 4 && (
              <>
                By including TV License, your <strong>first</strong> monthly
                payment will be higher{' '}
              </>
            )}
            {billDescription}
          </p>
        </div>

        <div className='flex w-28 flex-col space-y-2 '>
          <p>
            <span className='text-typography-primary font-medium'>
              £{calculateDurationPrice(price)}
            </span>
            {isMonthly ? '/month' : '/week'}
          </p>
          <p>per housemate</p>
        </div>
      </div>
    </>
  );
};

export const QuotePanelContent = ({ rate, setRate, step, serviceBill }) => {
  const { values } = useFormikContext();
  // const formatted_address = values?.address?.formatted_address;
  const quoteData = values?.billSelected ?? [];
  const noOfBillPayers = values?.bill_payers;
  const isMonthly = rate === 'monthly';

  const calculateDurationPrice = price => {
    if (!isMonthly) {
      return ((Number(price) * 12) / 52).toFixed(2);
    } else {
      return parseFloat(price).toFixed(2);
    }
  };

  // total price paid by an housemate
  const pricePerHousemate =
    quoteData?.length > 0
      ? quoteData
          ?.map(x => {
            let priceOfHousemate = Number(
              x?.prices?.[Number(noOfBillPayers) - 1]?.price_per_housemate
            );
            return priceOfHousemate
              ? priceOfHousemate
              : x?.prices?.[0]?.price_per_housemate;
          })
          .reduce(
            (previousService, currentService) =>
              Number(previousService) + Number(currentService),
            0
          )
      : 1.0; // 1.00 represents HB Service Fee
  return (
    <>
      {step > 3 && (
        <Tabs
          defaultValue={rate}
          onValueChange={value => setRate(value)}
          value={rate}
          className='flex w-full flex-col px-2 py-2 lg:px-16'
        >
          <TabsList>
            <TabsTrigger className='w-full' value='monthly'>
              Monthly
            </TabsTrigger>
            <TabsTrigger className='w-full' value='weekly'>
              Weekly
            </TabsTrigger>
          </TabsList>
        </Tabs>
      )}
      <div className='mt-2 px-1 lg:px-4'>
        <div className='flex w-full flex-col items-center justify-center'>
          <div className='w-full'>
            <>
              {quoteData?.length > 0 &&
                quoteData.map((bill, idx) => (
                  <BillSummaryCard
                    key={idx}
                    {...bill}
                    price={
                      bill?.prices?.[Number(noOfBillPayers) - 1]
                        .price_per_housemate
                        ? bill?.prices?.[Number(noOfBillPayers) - 1]
                            .price_per_housemate
                        : bill?.prices?.[0]?.price_per_housemate
                    }
                    billName={bill?.name}
                    isMonthly={isMonthly}
                    calculateDurationPrice={calculateDurationPrice}
                  />
                ))}
              <BillSummaryCard
                price={Number(serviceBill?.charge_per_housemate || 1)}
                group_name={'Homebox Service'}
                billDescription={
                  'A dedicated account manager with Monday - Friday support'
                }
                isMonthly={isMonthly}
                calculateDurationPrice={calculateDurationPrice}
              />
            </>
          </div>
        </div>
      </div>
      {/* TODO: Update first payment date */}
      {/* <div className='my-6 flex items-center space-x-2 px-4 '>
      <MdCalendarMonth className='h-4 w-4' />{' '}
      <p className='text-typography-primary text-sm'>
        <span className='font-medium'>First payment: </span> 1st April, 2023.
      </p>
    </div> */}

      <div className='my-4 px-0 pb-8 lg:px-4'>
        <div className='rounded-lg bg-neutral-100 px-6 py-4 text-neutral-600'>
          <div className='flex justify-between'>
            <div className='flex flex-col justify-end space-y-2 text-base lg:max-w-[200px]'>
              <p className='font-semibold '>Total</p>
            </div>

            <div className='flex flex-col space-y-2 '>
              <p className='text-base'>
                <span className='text-typography font-medium'>
                  {' '}
                  £
                  {calculateDurationPrice(
                    Number(pricePerHousemate) +
                      Number(serviceBill?.charge_per_housemate || 0)
                  )}
                </span>
                {isMonthly ? '/month' : '/week'}
              </p>
              <p className='text-sm font-normal'>per bill payer</p>
            </div>
          </div>
          <Divider className='my-2' />
          <div className='flex justify-end text-right'>
            <div className='flex flex-col space-y-2'>
              <p className='text-base'>
                <span className='text-typography font-medium'>
                  £
                  {calculateDurationPrice(
                    Number(pricePerHousemate * noOfBillPayers) +
                      Number(serviceBill?.total_charge || 0)
                  )}
                </span>
                {isMonthly ? '/month' : '/week'}
              </p>
              <p className='text-sm font-normal'>
                Total cost for {noOfBillPayers} housemates
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function QuotePanel({
  rate = 'monthly',
  setRate,
  step,
  serviceBill,
}) {
  return (
    <div className='border-background-secondary  w-full border-t-4 bg-white'>
      <div className='px-8 py-3'>
        <p className='text-typography text-lg font-semibold'>Bill Summary</p>
      </div>
      <QuotePanelContent
        rate={rate}
        setRate={setRate}
        step={step}
        serviceBill={serviceBill}
      />
    </div>
  );
}
