import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
const baseUrl = config.BASE_URL;
const partnerId = config.PARTNER_ID;

const getParams = arg => {
  const { postcode, housemates, address_id, is_student } = arg;
  const params = {};

  if (postcode) {
    params['postcode'] = postcode;
  }
  if (housemates) {
    params['housemates'] = housemates;
  }
  if (address_id) {
    params['address_id'] = address_id;
  }
  if (is_student) {
    params['is_student'] = is_student ? 1 : 0; // pass laravel request validation as string params fails
  }
  return params;
};

export const homeboxService = createApi({
  reducerPath: 'bbAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Access-Control-Allow-Origin', '*');
      headers.set('PartnerId', partnerId);
      headers.set(
        'Access-Control-Allow-Methods',
        'GET, POST, PUT,PATCH, DELETE, OPTIONS'
      );
      return headers;
    },
  }),
  endpoints: builder => ({
    findAddressByPostcode: builder.query({
      query: arg => {
        const { postCode } = arg;
        return {
          url: `open/address/find/${postCode}`,
        };
      },
      transformResponse: response => response?.data,
    }),

    // TODO: Implement full property lookup.
    // getPropertyAddress: builder.mutation({
    //   query: arg => {
    //     const { address } = arg;
    //     return {
    //       url: `open/property`,
    //       method: 'POST',
    //       body: address,
    //     };
    //   },
    //   transformResponse: response => response?.data,
    // }),

    // partner/package/tenant/quote
    getTenantPackageByPostcode: builder.query({
      query: arg => {
        const params = getParams(arg);
        return {
          url: `open/packages`,
          params,
        };
      },
      transformResponse: response => response?.data,
    }),

    // partner/package/bills-included/quote
    getCustomQuote: builder.query({
      query: arg => {
        const params = getParams(arg);
        return {
          url: `open/quote`,
          params,
        };
      },
    }),
    verifyCoupon: builder.query({
      query: arg => {
        const { coupon } = arg;
        return {
          url: `open/coupon/${coupon}`,
        };
      },
    }),

    createPartnerOrder: builder.mutation({
      query: data => {
        return {
          url: `open/order`,
          method: 'POST',
          body: data,
        };
      },
      // transformResponse: (response) => response?.data,
    }),
    updateTenantOrder: builder.mutation({
      query: data => {
        const { orderId } = data;
        return {
          url: `open/order/${orderId}/tenant`,
          method: 'POST',
          body: data,
        };
      },
    }),
    addBillPayerToTenantOrder: builder.mutation({
      query: data => {
        const { orderId } = data;
        data['order_id'] = orderId;
        return {
          url: `open/order/${orderId}/invite`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getBillPayersForTenantOrder: builder.query({
      query: arg => {
        const { orderId } = arg;
        return {
          url: `open/order/${orderId}/tenant`,
        };
      },
      transformResponse: response => response?.data,
    }),
    getInvitedBillPayersForTenantOrder: builder.query({
      query: arg => {
        const { orderId } = arg;
        return {
          url: `open/order/${orderId}/invite`,
        };
      },
      transformResponse: response => response?.data,
    }),
  }),
});

export const {
  useFindAddressByPostcodeQuery,
  useGetTenantPackageByPostcodeQuery,
  useGetCustomQuoteQuery,
  useCreatePartnerOrderMutation,
  useUpdateTenantOrderMutation,
  useAddBillPayerToTenantOrderMutation,
  useGetBillPayersForTenantOrderQuery,
  useGetInvitedBillPayersForTenantOrderQuery,
  useLazyVerifyCouponQuery,
} = homeboxService;
